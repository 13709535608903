// assets
import { CarOutlined } from '@ant-design/icons';

// icons
const icons = {
    CarOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const trips = {
    id: 'trips',
    title: 'Trips management',
    type: 'group',
    children: [
        {
            id: 'login1',
            title: 'Trips list',
            type: 'item',
            url: '/trips',
            icon: icons.CarOutlined
        }
    ]
};

export default trips;

import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import blockIcon from '@iconify/icons-akar-icons/block';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import trashIcon from '@iconify/icons-bi/trash';

import checkBoxFill from '@iconify/icons-akar-icons/check-box-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

function UserMoreMenu({ blockCallback, authorizeCallback, refuse, refuseCallback }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    return (
        <React.Fragment>
            <IconButton ref={ref} onClick={() => setIsOpen(true)} >
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem sx={{ color: 'text.secondary' }} onClick={authorizeCallback}>
                    <ListItemIcon>
                        <Icon icon={checkBoxFill} width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Authorize" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                <MenuItem sx={{ color: 'text.secondary' }} onClick={blockCallback}>
                    <ListItemIcon>
                        <Icon icon={blockIcon} width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Block" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                {refuse && (
                    <MenuItem sx={{ color: 'text.secondary' }} onClick={refuseCallback}>
                        <ListItemIcon>
                            <Icon icon={trashIcon} width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary="Refuse" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                )}
            </Menu>
        </React.Fragment>
    );
}

UserMoreMenu.propTypes = {
    blockCallback: PropTypes.func.isRequired,
    authorizeCallback: PropTypes.func.isRequired,
    refuse: PropTypes.bool.isRequired,
    refuseCallback: PropTypes.func
};

UserMoreMenu.defaultProps = {
    refuse: false
};
export default UserMoreMenu;

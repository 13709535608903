import axios from 'axios';
import store from 'redux/store';

const instance = axios.create({
    baseURL: `/api/v1`
});

instance.interceptors.request.use(
    async (config) => {
        const token = store.getState().auth.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (err) => Promise.reject(err)
);
export default instance;

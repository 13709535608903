// third-party
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

// project import
import menuReducer from './menu/menu.slice';
import authReducer from './auth/auth.slice';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
    reducer: {
        menu: menuReducer,
        auth: authReducer
    },
    middleware: (getDefaultMiddleware) =>
        process.env.NODE_ENV !== 'production' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware()
});

export default store;

// material-ui
import { Typography, Avatar, Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

// project import
import MainCard from 'components/MainCard';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from '../../api/server';
import { Link } from 'react-router-dom';
// ==============================|| SAMPLE PAGE ||============================== //
const Item = ({ label, src }) => {
    return (
        <Grid item>
            <Typography variant="body2">{label}</Typography>
            <a href={'https://elite-profiles-bucket.s3.eu-west-3.amazonaws.com/' + src} target="_blank" rel="noreferrer">
                <img src={'https://elite-profiles-bucket.s3.eu-west-3.amazonaws.com/' + src} />
            </a>
        </Grid>
    );
};
Item.propTypes = {
    label: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired
};

const DriverPage = () => {
    const { id } = useParams();
    const [driver, setDriver] = useState(null);
    const fetchCurrentDriver = async () => {
        const { data } = await axios.get(`/drivers/${id}`);
        setDriver(data);
    };
    useEffect(() => {
        fetchCurrentDriver();
    }, []);
    return (
        <MainCard title={id}>
            <div>
                {driver && (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                width: '25%'
                            }}
                        >
                            {driver.profileImageURL ? <Avatar alt={id} src={driver.profileImageURL} /> : <Avatar>{driver.fName[0]}</Avatar>}
                            <div>
                                <Typography variant="body2">Full name: {`${driver.lName} ${driver.fName}`}</Typography>
                                <Typography variant="body2">Phone number: {`${driver.phone}`}</Typography>
                                <Typography variant="body2">Conventional: {driver.isConventional ? 'yes' : 'no'}</Typography>
                                <Typography variant="body2">Taxi: {driver.taxi === 'taxi:berlin' ? 'BERLIN' : 'VAN'}</Typography>
                            </div>
                        </div>
                        <Box>
                            <Grid container spacing={2}>
                                {driver.parkingCardUrl && <Item label="Parking card" src={driver.parkingCardUrl} />}
                                {driver.driverLicenseUrl && <Item label="Driver license" src={driver.driverLicenseUrl} />}
                                {driver.cinUrls.length > 0 && <Item label="CIN card (front)" src={driver.cinUrls[0]} />}
                                {driver.cinUrls.length > 0 && <Item label="CIN card (back)" src={driver.cinUrls[1]} />}
                            </Grid>
                        </Box>
                    </div>
                )}
            </div>
        </MainCard>
    );
};

export default DriverPage;

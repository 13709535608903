import { useEffect, useState } from 'react';

import axios from 'api/server';
export default (authorization, start, end) => {
    const [passengers, setPassengers] = useState([]);
    const [errors, setErrors] = useState([]);
    const [totalPassengers, setTotalPassengers] = useState(0);
    const includeAuthorization = authorization ? `authorization=${authorization}&` : '';
    const fetchPassengers = async () => {
        try {
            const { data } = await axios.get(`/passengers/?${includeAuthorization}&start=${start}&end=${end}`);
            setPassengers(data.passengers);
            setTotalPassengers(data.totalPassengers);
        } catch (error) {
            setErrors(error.response.data.errors);
        }
    };
    const refetch = async () => {
        const { data } = await axios.get(`/passengers/?${includeAuthorization}&start=${start}&end=${end}`);
        setTotalPassengers(data.totalPassengers);
        setPassengers(data.passengers);
    };
    useEffect(() => {
        fetchPassengers();
    }, [authorization, start, end]);
    return [passengers, totalPassengers, errors, refetch];
};

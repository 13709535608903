import { useEffect, useState } from 'react';
// material-ui

// project import
import MainCard from 'components/MainCard';
import { makeStyles } from '@mui/styles';
import OrderTable from 'pages/dashboard/OrdersTable';
import { ButtonGroup, Button } from '@mui/material';
import axios from '../../api/server';
// ==============================|| SAMPLE PAGE ||============================== //
const useStyles = makeStyles((theme) => ({
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5% '
    }
}));
const TABLE_HEAD = [
    { id: 'name', label: 'Full name', alignRight: false },
    { id: 'id', label: 'Identifier', alignRight: false },
    { id: 'taxi:type', label: 'Taxi', alignRight: false },
    { id: 'conventional', label: 'Conventional', alignRight: false },
    { id: 'isVerified', label: 'Verified', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '' }
];
const generatePaginationArray = (total) => {
    const _optionsArray = [];
    for (let i = 0; i < total; i += 10) {
        _optionsArray.push(i / 10);
    }
    return _optionsArray;
};

const TripsPage = () => {
    const classes = useStyles();
    const [isConventional, setIsConventional] = useState(null);
    const [page, setPage] = useState(0);
    const [trips, setTrips] = useState({
        data: [],
        total: 0
    });
    const rowsPerPage = 10;
    const fetchTrips = async () => {
        const start = page == 0 ? 0 : page * rowsPerPage;
        const end = page == 0 ? rowsPerPage : page * rowsPerPage + rowsPerPage;
        const conventional = isConventional === null ? '' : `&conventional=${isConventional}`;
        const { data } = await axios.get(`/analytics/trips?start=${start}&end=${end}${conventional}`);
        setTrips({
            data: data.trips,
            total: data.total
        });
    };
    useEffect(() => {
        fetchTrips();
    }, [page]);
    useEffect(() => {
        fetchTrips();
        setPage(0);
    }, [isConventional]);
    return (
        <MainCard>
            <OrderTable handleFilterChange={setIsConventional} conventionalTaxi={isConventional} autoFetch={false} data={trips.data} />
            <ButtonGroup spacing={2} variant="outlined" aria-label="outlined primary button group" classes={{ root: classes.buttonGroup }}>
                {generatePaginationArray(trips.total).map((item) => {
                    return (
                        <Button
                            key={item}
                            onClick={() => {
                                setPage(item);
                            }}
                        >
                            {item}
                        </Button>
                    );
                })}
            </ButtonGroup>
        </MainCard>
    );
};

export default TripsPage;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import { Icon } from '@iconify/react';
// material-ui
import {
    Box,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    Button,
    Menu,
    MenuItem,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
    IconButton
} from '@mui/material';

// third-party
import NumberFormat from 'react-number-format';

// project import
import Dot from 'components/@extended/Dot';
import axios from '../../api/server';
import moment from 'moment';
const TripStatus = Object.freeze({
    Created: 'created',
    Cancelled: 'cancelled',
    AwaitingPayment: 'awaiting:payment',
    Completed: 'completed',
    LookingForDriver: 'looking:driver',
    AwaitingDriver: 'awaiting:driver',
    DriverNotFound: 'notfound:driver',
    DriverCancelled: 'cancelled:driver',
    PassengerPickedUp: 'pickedUp:passenger',
    Accepted: 'accepted',
    Paid: 'paid'
});

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: 'identifier',
        align: 'left',
        disablePadding: false,
        label: 'Identifier No.'
    },
    {
        id: 'date',
        align: 'left',
        disablePadding: false,
        label: 'Date'
    },
    {
        id: 'passenger number',
        align: 'center',
        disablePadding: true,
        label: 'Passengers Number'
    },
    {
        id: 'taxi conventional',
        align: 'left',
        disablePadding: true,
        label: 'Taxi Conventional'
    },
    {
        id: 'status',
        align: 'left',
        disablePadding: false,
        label: 'Status'
    },
    {
        id: 'evaluation',
        align: 'center',
        disablePadding: true,
        label: 'Evaluation'
    },
    {
        id: 'amount',
        align: 'right',
        disablePadding: false,
        label: 'Total Amount'
    }
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function OrderTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
    let color;
    let title;

    switch (status) {
        case TripStatus.LookingForDriver && TripStatus.AwaitingDriver && TripStatus.PassengerPickedUp && TripStatus.AwaitingPayment:
            color = 'warning';
            title = 'Pending';
            break;
        case TripStatus.Completed:
            color = 'success';
            title = 'Completed';
            break;
        case TripStatus.Paid:
            color = 'success';
            title = 'Paid';
            break;
        case TripStatus.DriverCancelled:
            color = 'error';
            title = 'Cancelled';
            break;
        case TripStatus.Cancelled:
            color = 'error';
            title = 'Rejected';
            break;
        case TripStatus.DriverNotFound:
            color = 'error';
            title = 'Driver not found';
            break;
        default:
            color = 'primary';
            title = 'None';
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color} />
            <Typography>{title}</Typography>
        </Stack>
    );
};

OrderStatus.propTypes = {
    status: PropTypes.string
};
UsersSort.propTypes = {
    labels: PropTypes.array,
    filter: PropTypes.string,
    handleFilterChange: PropTypes.func
};
UsersSort.defaultProps = {
    labels: [],
    filter: 'All',
    handleFilterChange: () => {}
};
function UsersSort({ labels, filter, handleFilterChange }) {
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };
    const boolToLabel = (input) => {
        if (input === null) {
            return 'all';
        }
        if (input) {
            return 'conventional';
        } else {
            return 'normal';
        }
    };
    const labelToBool = (label) => {
        switch (label) {
            case 'all':
                return null;
            case 'normal':
                return false;
            case 'conventional':
                return true;
            default:
                return null;
        }
    };
    return (
        <React.Fragment>
            <Button
                color="inherit"
                disableRipple
                onClick={handleOpen}
                // endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
            >
                filter by taxi type:&nbsp;
                <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    {labels.filter((item) => item.value == boolToLabel(filter))[0].label}
                </Typography>
            </Button>
            <Menu
                keepMounted
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {labels.map((option, index) => (
                    <MenuItem
                        key={index}
                        selected={option.value === boolToLabel(filter)}
                        onClick={() => {
                            handleFilterChange(labelToBool(option.value));
                            handleClose();
                        }}
                        sx={{ typography: 'body2' }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}

// ==============================|| ORDER TABLE ||============================== //
const tableRows = (data) => {
    return data.map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
            <TableRow hover role="checkbox" sx={{ '&:last-child td, &:last-child th': { border: 0 } }} tabIndex={-1} key={row.id}>
                <TableCell component="th" id={labelId} scope="row" align="left">
                    <Link color="secondary" component={RouterLink} to="">
                        {row.id}
                    </Link>
                </TableCell>
                <TableCell align="left">{moment(row.createdAt).format('LLLL')}</TableCell>
                <TableCell align="center">{row.passengersNumber}</TableCell>
                <TableCell align="center">{row.conventionalTaxi ? 'Yes' : 'No'}</TableCell>
                <TableCell align="left">
                    <OrderStatus status={row.status} />
                </TableCell>
                <TableCell align="center">{row.evaluation ? `${row.evaluation}/5` : 'Unavailable'}</TableCell>
                <TableCell align="right">
                    <NumberFormat value={row.price?.amount} displayType="text" thousandSeparator prefix="€" />
                </TableCell>
            </TableRow>
        );
    });
};
const FilterByOptions = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Conventional trips',
        value: 'conventional'
    },
    {
        label: 'Normal trips',
        value: 'normal'
    }
];
function OrderTable({ autoFetch, data, conventionalTaxi, handleFilterChange }) {
    const fetchTrips = async () => {
        const { data } = await axios.get('/analytics/trips');
        setTrips(data.trips);
    };
    const [trips, setTrips] = useState([]);

    useEffect(() => {
        if (autoFetch) {
            fetchTrips();
        }
    }, []);
    return (
        <Box>
            {!autoFetch && (
                <div>
                    <Tooltip title="Filter list">
                        <IconButton onClick={() => {}}>
                            <Icon icon={roundFilterList} />
                        </IconButton>
                    </Tooltip>

                    <UsersSort labels={FilterByOptions} filter={conventionalTaxi} handleFilterChange={handleFilterChange} />
                </div>
            )}
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' }
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        },
                        '& .MuiTableCell-root:last-child': {
                            pr: 3
                        }
                    }}
                >
                    <OrderTableHead />
                    <TableBody>{tableRows(autoFetch ? trips : data)}</TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

OrderTable.propTypes = {
    autoFetch: PropTypes.bool,
    data: PropTypes.array,
    handleFilterChange: PropTypes.func,
    conventionalTaxi: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};
OrderTable.defaultProps = {
    autoFetch: true,
    data: [],
    conventionalTaxi: null,
    handleFilterChange: () => {}
};
export default OrderTable;

// project import
// import pages from './pages';
import dashboard from './dashboard';
import users from './users';
import trips from './trips';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, users, trips]
};

export default menuItems;

import { useState } from 'react';
// material
import { Menu, Button, MenuItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
    { value: 'featured', label: 'Featured' },
    { value: 'newest', label: 'Newest' },
    { value: 'priceDesc', label: 'Price: High-Low' },
    { value: 'priceAsc', label: 'Price: Low-High' }
];
UsersSort.propTypes = {
    sort_by_options: PropTypes.array,
    authorization: PropTypes.string,
    handleAuthorizationChange: PropTypes.func
};
UsersSort.defaultProps = {
    sort_by_options: [],
    authorization: 'All',
    handleAuthorizationChange: () => {}
};
export default function UsersSort({ sort_by_options, authorization, handleAuthorizationChange }) {
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <Button
                color="inherit"
                disableRipple
                onClick={handleOpen}
                // endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
            >
                filter by authorization:&nbsp;
                <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    {sort_by_options.filter((item) => item.value == authorization)[0].label}
                </Typography>
            </Button>
            <Menu
                keepMounted
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {sort_by_options.map((option) => (
                    <MenuItem
                        key={option.label}
                        selected={option.value === authorization}
                        onClick={() => {
                            handleAuthorizationChange(option.value);
                            handleClose();
                        }}
                        sx={{ typography: 'body2' }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

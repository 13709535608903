import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
// material-ui

import {
    Card,
    Table,
    Stack,
    Avatar,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    ButtonGroup,
    Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// project import

import useFetchDrivers from 'hooks/users/useFetchDrivers';
import axios from '../../api/server';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/user';

// ==============================|| SAMPLE PAGE ||============================== //
const UserAuthorizationStatus = Object.freeze({
    Authorized: 'user:authorized',
    Blocked: 'user:blocked',
    DriverAwaitingModeration: 'driver:awaiting-moderation',
    DriverRefused: 'driver:refused',
    Unauthorized: 'user:unauthorized'
});
const TABLE_HEAD = [
    { id: 'name', label: 'Full name', alignRight: false },
    { id: 'id', label: 'Identifier', alignRight: false },
    { id: 'taxi:type', label: 'Taxi', alignRight: false },
    { id: 'conventional', label: 'Conventional', alignRight: false },
    { id: 'isVerified', label: 'Verified', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '' }
];
const FilterByOptions = [
    {
        label: 'All',
        value: null
    },
    {
        label: 'Blocked drivers',
        value: UserAuthorizationStatus.Blocked
    },
    {
        label: 'Drivers awaiting moderation',
        value: UserAuthorizationStatus.DriverAwaitingModeration
    },
    {
        label: 'Authorized drivers',
        value: UserAuthorizationStatus.Authorized
    },
    {
        label: 'Refused drivers',
        value: UserAuthorizationStatus.DriverRefused
    },
    {
        label: 'Unauthorized drivers',
        value: UserAuthorizationStatus.Unauthorized
    }
];
const useStyles = makeStyles((theme) => ({
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5% '
    }
}));
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const generatePaginationArray = (total) => {
    const _optionsArray = [];
    for (let i = 0; i < total; i += 5) {
        _optionsArray.push(i / 5);
    }
    return _optionsArray;
};

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

const DriversPage = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [authorization, setAuthorization] = useState(null);
    const navigate = useNavigate();

    const [drivers, totalDrivers, errors, setRefetch] = useFetchDrivers(
        authorization,
        page == 0 ? 0 : page * rowsPerPage,
        page == 0 ? rowsPerPage : page * rowsPerPage + rowsPerPage
    );

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = drivers.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const authorizeDriver = async (id) => {
        await axios.put(`/drivers/authorizations/${id}`, {
            authorization: UserAuthorizationStatus.Authorized
        });
        setRefetch(true)
    };
    const blockDriver = async (id) => {
        await axios.put(`/drivers/authorizations/${id}`, {
            authorization: UserAuthorizationStatus.Blocked
        });
        setRefetch(true)
    };
    const refuseDriver = async (id) => {
        await axios.put(`/drivers/authorizations/${id}`, {
            authorization: UserAuthorizationStatus.DriverRefused
        });
        setRefetch(true)
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    useEffect(() => {
        setPage(0);
    }, [authorization]);

    const filteredUsers = applySortFilter(drivers, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;
    return (
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Drivers
                </Typography>
            </Stack>

            <Card>
                <UserListToolbar
                    sort_by_options={FilterByOptions}
                    authorization={authorization}
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    handleAuthorizationChange={setAuthorization}
                />

                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={drivers.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredUsers.map((row) => {
                                    const {
                                        id,
                                        fName,
                                        lName,
                                        authorizationStatus,
                                        isEmailVerified,
                                        isPhoneVerified,
                                        taxi,
                                        isConventional,
                                        profileImageURL
                                    } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                            </TableCell>
                                            <TableCell   
                                            onClick={() => {
                                                navigate(`/users/driver/${id}`, { replace: true });
                                            }}
                                             component="th" scope="row" padding="none">   
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Avatar alt={fName} src={profileImageURL} />
                                                    <Typography variant="subtitle2" noWrap>
                                                        {fName + ' ' + lName}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">{id}</TableCell>
                                            <TableCell align="left">{taxi === 'taxi:van' ? 'VAN' : 'BERLIN'}</TableCell>
                                            <TableCell align="left">{isConventional ? 'Yes' : 'No'}</TableCell>
                                            <TableCell align="left">{isEmailVerified && isPhoneVerified ? 'Yes' : 'No'}</TableCell>
                                            <TableCell align="left">
                                                <Label
                                                    variant="ghost"
                                                    color={(authorizationStatus === 'user:authorized' && 'success') || 'error'}
                                                >
                                                    {authorizationStatus}
                                                </Label>
                                            </TableCell>

                                            <TableCell align="right">
                                                <UserMoreMenu
                                                    refuse
                                                    blockCallback={() => {
                                                        blockDriver(id);
                                                    }}
                                                    authorizeCallback={() => {
                                                        authorizeDriver(id);
                                                    }}
                                                    refuseCallback={() => {
                                                        refuseDriver(id);
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            {drivers.length == 0 && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Card>
            <ButtonGroup spacing={2} variant="outlined" aria-label="outlined primary button group" classes={{ root: classes.buttonGroup }}>
                {generatePaginationArray(authorization == null ? totalDrivers : drivers.length).map((item) => {
                    return (
                        <Button
                            key={item}
                            onClick={() => {
                                setPage(item);
                            }}
                        >
                            {item}
                        </Button>
                    );
                })}
            </ButtonGroup>
        </Container>
    );
};

export default DriversPage;

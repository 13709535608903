import { useEffect, useState } from 'react';

import axios from 'api/server';
export default (authorization, start, end) => {
    const [drivers, setDrivers] = useState([]);
    const [errors, setErrors] = useState([]);
    const [totalDrivers, setTotalDrivers] = useState(0);
    const [refetch, setRefetch] = useState(false);
    const fetchDrivers = async () => {
        const includeAuthorization = authorization ? `authorization=${authorization}&` : '';
        try {
            const { data } = await axios.get(`/drivers/?${includeAuthorization}&start=${start}&end=${end}`);
            setDrivers(data.drivers);
            setTotalDrivers(data.totalDrivers);
            setRefetch(false)
        } catch (error) {
            setErrors(error.response.data.errors);
        }
    };

    useEffect(() => {
        fetchDrivers();
    }, [authorization, start, end, refetch]);
    return [drivers, totalDrivers, errors, setRefetch];
};

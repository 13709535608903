import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null,
    currentAdmin: null,
    errors: []
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        addAuthErrs: (state, action) => {
            state.errors = action.payload;
        },
        clearAuthErrs: (state) => {
            state.errors = [];
        },
        addToken: (state, action) => {
            state.token = action.payload;
        },
        removeToken: (state) => {
            state.token = null;
        },
        setCurrentAdmin: (state, action) => {
            state.currentAdmin = action.payload;
        },
        removeCurrentAdmin: (state) => {
            state.currentAdmin = null;
        }
    }
});
export const { addAuthErrs, clearAuthErrs, addToken, removeToken, setCurrentAdmin, removeCurrentAdmin } = authSlice.actions;
export default authSlice.reducer;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { adminTokenSelector } from 'redux/auth/auth.selectors';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const navigate = useNavigate();
    const token = useSelector(adminTokenSelector);

    useEffect(() => {
        if (token) {
            navigate('/dashboard/default');
        }
    }, [token]);

    return (
        <>
            <Outlet />
        </>
    );
};

export default MinimalLayout;
